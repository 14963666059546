<template>
  <div>
    <form action="">
      <VueFullScreenFileDrop @drop="onDrop"></VueFullScreenFileDrop>
      <input
        id="fileUpload"
        multiple
        type="file"
        hidden
        @change="attachFiles($event)"
      />
      <div id="snackbar">
        <Progress :radius="50" :strokeWidth="10" :value="progress">
          <template v-slot:footer>
            <b>Uploading</b>
          </template>
        </Progress>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import Progress from "easy-circular-progress";
export default {
  watch: {
    chunks(n, o) {
      if (n.length > 0) {
        this.upload();
      }
    },
  },
  components: {
    VueFullScreenFileDrop,
    Progress,
  },
  props: {
    currentPath: Object,
    guestName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      fullsize: null,
      formuploadfiles: new FormData(),
      chunks: [],
      uploaded: 0,
      first: false,
      openSnackbar: false,
    };
  },
  computed: {
    progress() {
    if(this.fullsize!=null){
        return Math.min(
          Math.floor((this.uploaded * 100) / this.fullsize),
          100
        );
    }
    return 0
      
    },
    formData() {
      let formData = new FormData();

      formData.set("path", this.currentPath.path);
      formData.set("overwrite", true);
      formData.set("guestName", this.guestName);
      formData.set("isFirst", this.first);
      formData.set("isLast", this.chunks[0].last);
      formData.set("file", this.chunks[0].file, `${this.chunks[0].name}.part`);

      return formData;
    },
    config() {
      return {
        method: "POST",
        data: this.formData,
        url: "/filemanager/chunkupload",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + this.$store.getters.token,
        },
        onUploadProgress: (event) => {
          this.UploadProgress(event);
        },
      };
    },
  },

  methods: {
    operUpload() {
      document.getElementById("fileUpload").click();
    },
    onDrop(formData, files) {
      this.loadFiles(files);
    },
    attachFiles($event) {
      let selectedFiles = $event.target.files;
      if (!selectedFiles.length) {
        return false;
      }
      this.loadFiles(selectedFiles);
    },
    loadFiles(files) {
      this.uploaded = 0;
      this.fullsize = 0
      if (!this.currentPath.searchActive) {
        for (var i = 0; i < files.length; i++) {
          this.uploadFile(files[i]);
        }
      } else {
        this.$message({
          message: "Action Blocked",
          type: "error",
          customClass: "message-error",
        });
      }
    },
    uploadFile(file) {
      this.createChunks(file);
    },
    UploadProgress(event) {
      this.uploaded += event.loaded;
      var x = document.getElementById("snackbar");
      var progress = Math.min(
        Math.floor((this.uploaded * 100) / this.fullsize),
        100
      );
      if (!this.openSnackbar) {
        x.className = "show";
        this.openSnackbar = true;
      }
    },
    upload() {
      axios(this.config)
        .then((response) => {
          this.chunks.shift();
          if (response.data.result.message == "Cloud upload started") {
            var x = document.getElementById("snackbar");
            this.openSnackbar = false;
            setTimeout(function() {
              x.className = x.className.replace("show", "");
            }, 3000);
          }
        })
        .catch((error) => {});
    },
    createChunks(file) {
      let size = 524288,
      chunks = Math.ceil(file.size / size);
      this.first = true;
      let name = file.name
      this.fullsize += file.size
      for (let i = 0; i < chunks; i++) {
        this.chunks.push({'file' : file.slice(
            i * size,
            Math.min(i * size + size, file.size),
            file.type
          ), 'name' : name, 'last' : chunks-i === 1});
        this.first = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 90%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  z-index: 9999;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 120s;
  animation: fadein 0.5s, fadeout 0.5s 120s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
